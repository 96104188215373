import { CacheProvider, EmotionCache } from "@emotion/react";
import { CartProvider, GoogleAnalytics, TranslationContext } from "@akte-mord/common";
import { ConsentProvider } from "./user-consent-management";
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import { InnerErrorBoundary } from "./error-boundary";
import { OverlayProvider } from "@react-aria/overlays";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Theme } from "@mui/material";
import NiceModal from "@ebay/nice-modal-react";
import React from "react";

export const Providers: React.FC<{
  children: React.ReactNode;
  emotionCache: EmotionCache;
  theme: Theme;
  queryClient: QueryClient;
}> = ({ children, emotionCache, theme, queryClient }) => (
  <TranslationContext.Provider
    value={{
      routes: {
        cart: "/warenkorb",
        checkout: "/kasse",
        shippingAndDeliveryInformation: "/versandkosten-und-lieferzeiten",
        missingMaterials: "/hilfe/fehlende-materialien",
        solution: "/loesung",
      },
      useCart: true,
    }}
  >
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            ".react-modal-sheet-container": {
              "&:focus": {
                outline: "none",
                appearance: "none",
              },
            },
          }}
        />
        <InnerErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <OverlayProvider>
              <NiceModal.Provider>
                <ConsentProvider>
                  <GoogleAnalytics
                    grantId="google-analytics"
                    trackingId={process.env.NEXT_PUBLIC_GA_TRACKING_ID ?? ""}
                  >
                    <CartProvider>{children}</CartProvider>
                  </GoogleAnalytics>
                </ConsentProvider>
              </NiceModal.Provider>
            </OverlayProvider>
          </QueryClientProvider>
        </InnerErrorBoundary>
      </ThemeProvider>
    </CacheProvider>
  </TranslationContext.Provider>
);
