import "swiper/css";
import { appWithTranslation } from "next-i18next";
import { createEmotionCache, theme } from "../theme";
import { DefaultSeo } from "next-seo";
import { EmotionCache } from "@emotion/react";
import { Providers } from "../providers";
import { QueryClient } from "@tanstack/react-query";
import { SEO_CONFIG } from "../next-seo.config";
import Head from "next/head";
import i18nConfig from "../../next-i18next.config";
import React from "react";
import type { AppProps } from "next/app";
import type { NextPage } from "next";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
  emotionCache?: EmotionCache;
}

export const App: React.FC<MyAppProps> = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) => {
  const getLayout = Component.getLayout || ((page) => page);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      },
    },
  });

  return (
    <Providers emotionCache={emotionCache} queryClient={queryClient} theme={theme}>
      <DefaultSeo {...SEO_CONFIG} />
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      {getLayout(<Component {...pageProps} />)}
    </Providers>
  );
};

export default appWithTranslation(App, i18nConfig);
