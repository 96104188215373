const config = {
  i18n: {
    locales: ["de"],
    defaultLocale: "de",
    defaultNS: "c-common",
    returnNull: false,
  },
};

export default config;
